var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-top"},[_c('div',{staticClass:"center-box"},[_c('div',{staticClass:"header-top_left"},[_c('img',{attrs:{"src":require("./img/logo.png")},on:{"click":function($event){return _vm.toAbout('/')}}})]),_c('div',{staticClass:"header-top_right"},[_c('div',{staticClass:"tab-item",on:{"click":function($event){return _vm.toAbout('/about')}}},[_vm._v(" 关于我们 "),_c('div',{staticClass:"active-line"})]),_c('div',{staticClass:"tab-item",on:{"click":function($event){return _vm.toService()}}},[_vm._v(" 服务内容 ")]),_c('div',{staticClass:"tab-item",staticStyle:{"position":"relative"},on:{"click":function($event){_vm.showpop=!_vm.showpop}}},[_vm._v(" 加入我们 "),_c('div',{class:['pop-code',_vm.showpop?'show-pop':'' ]},[_c('img',{attrs:{"src":require("./img/image.jpg"),"alt":""}})])]),_vm._m(0)])])]),_vm._m(1),_vm._m(2)])]),_c('section',{staticClass:"section"},[_c('div',{staticClass:"content"},[_vm._m(3),_c('div',{staticClass:"org"},[_vm._m(4),_c('div',{staticClass:"org-list"},[_vm._m(5),_c('div',{staticClass:"org-top-list"},_vm._l((_vm.org),function(item,index){return _c('div',{key:index,staticClass:"org-top-list-item"},[_c('div',{staticClass:"user-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"remark"},[_vm._v(_vm._s(item.work))])])}),0)])])])]),_c('footer',{staticClass:"footer"},[_vm._m(6),_c('div',{staticClass:"footer-item",staticStyle:{"width":"400px"}},[_c('div',{staticClass:"footer-item-title"},[_vm._v("联系我们")]),_c('div',{staticClass:"footer-item-label"},[_vm._v("咨询热线：020 - 83513470")]),_c('div',{staticClass:"footer-item-label"},[_vm._v("客服邮箱：copyright-royalty@yinchuangfu.org.cn")]),_c('div',{staticClass:"footer-item-label",staticStyle:{"cursor":"pointer"},on:{"click":_vm.icp}},[_vm._v("粤ICP备2022042374号-1")])]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-item"},[_c('img',{staticClass:"search-icon",attrs:{"src":require("./img/Search.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-content"},[_c('div',{staticClass:"title"},[_vm._v(" 关于我们 "),_c('div',[_vm._v("About us")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title-content"},[_c('div',{staticClass:"top-title"},[_c('p',[_vm._v("中心依托广州市大湾区文化交流促进中心设立，致力于以创新模 式为大湾区音乐产业提供专业服务。乐通文化作为指定运营方，携手音乐人网，通过构建辐射全国音乐产业的一站式音乐公共服务平台，深度服务音乐行业。")]),_c('p',[_vm._v("中心以“让音乐充满价值”为使命，聚焦于音乐作品的创作、传播和保护，并将服务延伸至演艺和教育培训等领域。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_c('div',{staticClass:"copyright-title"},[_c('div',{staticClass:"left-line"}),_c('div',{staticClass:"img-title"},[_vm._v("我们的服务宗旨")]),_c('div',{staticClass:"right-line"})]),_c('div',{staticClass:"copyright-tips"},[_vm._v(" “中心”的设立，将联合大湾区音乐产业从业机构和人员，引入公共法律服务资源，依托先进的互联网技术手段，协同音乐产业上、中、下游，共同打造符合数字时代音乐产业发展规律的公共服务产品体系。 ")]),_c('div',{staticClass:"tab-box"},[_c('div',{staticClass:"tab-box-item"},[_c('div',{staticClass:"tab-box-item-title"},[_vm._v("· 上游 ·")]),_c('div',{staticClass:"tab-box-item-label"},[_vm._v("音乐内容孵化")]),_c('div',{staticClass:"tab-box-item-label"},[_vm._v("音乐确权保护")]),_c('div',{staticClass:"tab-box-item-label"},[_vm._v("音乐人才孵化")])]),_c('div',{staticClass:"tab-box-item"},[_c('div',{staticClass:"tab-box-item-title blue-color"},[_vm._v("· 中游 ·")]),_c('div',{staticClass:"tab-box-item-label"},[_vm._v("音乐版权交易")]),_c('div',{staticClass:"tab-box-item-label"},[_vm._v("优质内容输出")]),_c('div',{staticClass:"tab-box-item-label"},[_vm._v("公证用权服务")])]),_c('div',{staticClass:"tab-box-item"},[_c('div',{staticClass:"tab-box-item-title gren-color"},[_vm._v("· 下游 ·")]),_c('div',{staticClass:"tab-box-item-label"},[_vm._v("音乐维权服务")]),_c('div',{staticClass:"tab-box-item-label"},[_vm._v("音乐交易管理")]),_c('div',{staticClass:"tab-box-item-label"},[_vm._v("行业税务优化")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"org-title"},[_c('div',{staticClass:"left-line"}),_c('div',{staticClass:"img-title"},[_vm._v("组织架构")]),_c('div',{staticClass:"right-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w"},[_c('div',{staticClass:"org-top-list"},[_c('div',{staticClass:"org-top-list-item"},[_c('div',{staticClass:"user-name"},[_vm._v("何东桦")]),_c('div',{staticClass:"remark"},[_vm._v("主任")])]),_c('div',{staticClass:"org-top-list-item org-top-list-item2"},[_c('div',{staticClass:"user-name"},[_vm._v("唐毅")]),_c('div',{staticClass:"remark"},[_vm._v("副主任")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-item"},[_c('div',{staticClass:"footer-item-title"},[_vm._v("友情链接")]),_c('div',{staticClass:"footer-item-label"},[_vm._v("广东南粤公证处")]),_c('div',{staticClass:"footer-item-label"},[_vm._v("广州市大湾区文化交流促进中心")]),_c('div',{staticClass:"footer-item-label"},[_vm._v("广东省流行音乐协会音乐版权委员会")]),_c('div',{staticClass:"footer-item-label"},[_vm._v("广东省演出行业协会")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-qrcode"},[_c('img',{attrs:{"src":require("./img/image 19.png"),"alt":""}})])
}]

export { render, staticRenderFns }