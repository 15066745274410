import Vue from 'vue'
import app from './app.vue'

import router from './router' 
Vue.config.productionTip = false
import './app.css'
new Vue({
  render: h => h(app),
  router 
}).$mount('#app')
