<template>
  <div>
    <header>
      <div class="header">
        <div class="header-top">
          <div class="center-box">
            <div class="header-top_left">
              <img @click="toAbout('/')" src="./img/logo.png" />
            </div>
            <div class="header-top_right">
              <!-- <div v-for="(item, index) in tabbar" @click="index == 0 ? toAbout('/about') : ''" :key="index"
                class="tab-item">
                {{ item }}
                <div v-if="index===0" class="active-line"></div>
              </div> -->
              <div @click="toAbout('/about')"
                class="tab-item">
                关于我们
                <div class="active-line"></div>
              </div>
              <div @click="toService()"
                class="tab-item">
                服务内容
              </div>
              <div 
                class="tab-item" @click="showpop=!showpop" style="position:relative">
                加入我们
                <div :class="['pop-code',showpop?'show-pop':'' ]" >
                  <img src="./img/image.jpg" alt="">
                </div>
              </div>
              <div class="tab-item">
                <img class="search-icon" src="./img/Search.png" />
              </div>
             
            </div>
          </div>
        </div>
        <div class="header-content">
          <div class="title">
            关于我们
            <div>About us</div>
          </div>
        </div>
        <div class="top-title-content">
          <div class="top-title">
            <p>中心依托广州市大湾区文化交流促进中心设立，致力于以创新模 式为大湾区音乐产业提供专业服务。乐通文化作为指定运营方，携手音乐人网，通过构建辐射全国音乐产业的一站式音乐公共服务平台，深度服务音乐行业。</p>
            <p>中心以“让音乐充满价值”为使命，聚焦于音乐作品的创作、传播和保护，并将服务延伸至演艺和教育培训等领域。</p>
          </div>
        </div>
      
      </div>
    </header>
    <section class="section">
      <div class="content">
        <div class="copyright">
          <div class="copyright-title">
            <div class="left-line"></div>
            <div class="img-title">我们的服务宗旨</div>
            <div class="right-line"></div>
          </div>
          <div class="copyright-tips">
            “中心”的设立，将联合大湾区音乐产业从业机构和人员，引入公共法律服务资源，依托先进的互联网技术手段，协同音乐产业上、中、下游，共同打造符合数字时代音乐产业发展规律的公共服务产品体系。
          </div>
          <div class="tab-box">
            <div class="tab-box-item">
              <div class="tab-box-item-title">· 上游 ·</div>
              <div class="tab-box-item-label">音乐内容孵化</div>
              <div class="tab-box-item-label">音乐确权保护</div>
              <div class="tab-box-item-label">音乐人才孵化</div>
            </div>
            <div class="tab-box-item">
              <div class="tab-box-item-title blue-color">· 中游 ·</div>
              <div class="tab-box-item-label">音乐版权交易</div>
              <div class="tab-box-item-label">优质内容输出</div>
              <div class="tab-box-item-label">公证用权服务</div>
            </div>
            <div class="tab-box-item">
              <div class="tab-box-item-title gren-color">· 下游 ·</div>
              <div class="tab-box-item-label">音乐维权服务</div>
              <div class="tab-box-item-label">音乐交易管理</div>
              <div class="tab-box-item-label">行业税务优化</div>
            </div>
          </div>
        </div>

        <div class="org">
          <!-- <div class="org-title">组织架构</div> -->
          <div class="org-title ">
            <div class="left-line"></div>
            <div class="img-title">组织架构</div>
            <div class="right-line"></div>
          </div>
          <div class="org-list">
            <div class="w">
              <div class="org-top-list">
                <div class="org-top-list-item">
                  <div class="user-name">何东桦</div>
                  <div class="remark">主任</div>
                </div>
                <div class="org-top-list-item org-top-list-item2">
                  <div class="user-name">唐毅</div>
                  <div class="remark">副主任</div>
                </div>
              </div>
            </div>
            <div class="org-top-list">
              <div class="org-top-list-item" v-for="(item, index) in org" :key="index">
                <div class="user-name">{{ item.name }}</div>
                <div class="remark">{{ item.work }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="footer">
      <div class="footer-item">
        <div class="footer-item-title">友情链接</div>
        <div class="footer-item-label">广东南粤公证处</div>
        <div class="footer-item-label">广州市大湾区文化交流促进中心</div>
        <div class="footer-item-label">广东省流行音乐协会音乐版权委员会</div>
        <div class="footer-item-label">广东省演出行业协会</div>
      </div>
      <div class="footer-item"  style="width: 400px;">
        <div class="footer-item-title">联系我们</div>
        <div class="footer-item-label">咨询热线：020 - 83513470</div>
        <!-- <div class="footer-item-label">服务时间：08:30 - 18:00</div> -->
        <div class="footer-item-label">客服邮箱：copyright-royalty@yinchuangfu.org.cn</div>
        <div class="footer-item-label" style="cursor: pointer;" @click="icp" >粤ICP备2022042374号-1</div>
      </div>
      <div class="footer-qrcode">
        <img src="./img/image 19.png" alt="" />
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      tabbar: ["关于我们", "服务内容", "加入我们"],
      org: [
        {
          name: "谢嘉懿",
          work: "版权部门·干事",
        },
        {
          name: "阮曼菁",
          work: "版权部门·干事",
        },
        {
          name: "李狄狄",
          work: "版权部门·干事",
        },
        {
          name: "梁智达",
          work: "版权部门·干事",
        },
        {
          name: "林冬媛",
          work: "演艺部门·干事",
        },
      ],
      showpop:false
    };
  },
  methods: {
    toAbout(path) {
      this.$router.push(path);
    },
    icp(){
      window.open('https://beian.miit.gov.cn/')
    }
  },
};
</script>

<style scoped>
.header {
  width: 1920px;
  height: 560px;
  box-sizing: border-box;
  background-image: linear-gradient(to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)),
    url("./img/about.png");
  background-repeat: no-repeat;
  background-size: 1920px 560px;
}

.header .header-top {
  display: flex;
  width: 1920px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.header .header-top .center-box {
  width: 1440px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.header .header-top .center-box .header-top_left img {
  padding-top: 36px;
  width: 186px;
  height: 56px;
  cursor: pointer;
}

.header .header-top .center-box .header-top_right {
  display: inline-flex;
  padding-top: 30px;
  flex-wrap: nowrap;
  align-items: center;

}
.header .active-line{
  width: 60px;
  height: 4px;
  background: #FF57D0;
  position:absolute;
  /* left: 60px; */
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}
.pop-code img{
  position: absolute;
  left: -50%;
  top: 100%;
  margin-top: 15px;
  transform: translateY(0px);
  height: 0;
  z-index: 98;
  width: 200px
}
.header .income:hover {
  color: #FF57D0;;
}

/* .header .income:hover .pop-code img{
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  height: 200px;
  width: 200px;
} */
.show-pop img{
  transform: translateY(0);
  transition: all 0.1s ease-in-out;
  height: 200px!important;
  width: 200px!important;;
}
.header .header-top .center-box .header-top_right .tab-item {
  /* margin: 0 20px; */
  margin-left: 56px;
  position: relative;
  cursor: pointer;
}

.header .search-icon {
  width: 24px;
  height: 24px;
}

.header .header-content .title {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  margin-left: 240px;
  margin-top: 152px;
  text-align: left;
  text-transform: capitalize;
  color: #272d38;
}

.header .header-content .title div {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-top: 32px;
  text-transform: capitalize;
  color: #555b66;
}
.top-title-content{
display: flex;
justify-content: center;
margin-top: 120px;
}
.top-title-content .top-title {
width: 1440px;
font-weight: 400;
font-size: 24px;
text-transform: capitalize;
color: #272d38;
text-align: left;
}

.section {
  display: flex;
  justify-content: center;
}

.content {
  width: 1440px;
}



/* copyright start */
.copyright {
  margin-top: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.copyright-title {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 120px; */
}

.img-title {

  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #272d38;
  margin: 0 40px;
}

.left-line {
  width: 120px;
  height: 4px;
  background: linear-gradient(90deg, rgba(167, 254, 183, 0) 0%, #27beff 100%);
}

.right-line {
  width: 120px;
  height: 4px;
  background: linear-gradient(90deg, rgba(167, 254, 183, 0) 0%, #27beff 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.copyright-tips {

  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-top: 32px;
  text-align: center;
  text-transform: capitalize;
  color: #9ba1ad;
}

.copyright .tab-box {
  box-sizing: border-box;
  margin-top: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px rgba(228, 200, 217, 0.25);
}

.copyright .tab-box .tab-box-item {
  width: 440px;
  height: 360px;

  background: #ffffff;
  border: 1px solid #edf6ff;
  box-shadow: 0px 8px 20px rgba(228, 200, 217, 0.25);
}

.copyright .tab-box .tab-box-item:nth-child(2) {
  margin: 0 60px;
}

.copyright .tab-box-item-title {
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #ff9200;
  margin-top: 60px;
}

.blue-color {
  color: #0089ff !important;
}

.gren-color {
  color: #00ebb6 !important;
}

.copyright .tab-box-item-label {
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  text-transform: capitalize;
  color: #555b66;
  margin-top: 40px;
}

/* copyright end */

/* org */
.org {
  margin-top: 200px;
}

.org-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #272d38;
  margin-bottom: 60px;
}

.org-list {
  width: 1440px;
  height: 340px;
  background: #ffffff;
  display: flex;
  flex-direction: column;

  align-items: center;
  border: 1px solid #edf6ff;
  box-shadow: 0px 8px 20px rgba(228, 200, 217, 0.25);
}

.org-top-list {
  display: inline-flex;
  width: 1440px;
}

.org-top-list-item {
  width: 288px;
  text-align: left;
  padding-left: 60px;
  margin-top: 60px;
}

.org-top-list-item2 {
  padding-left: 0px;
}

.user-name {
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  text-transform: capitalize;
  color: #272d38;
}

.remark {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  color: #9ba1ad;
  margin-top: 24px;
}

/* org end */
/* footer */
.footer {
  display: flex;
  justify-content: center;
  margin-top: 260px;
  max-width: 1440px;
  margin-left: 240px;
  margin-bottom: 100px;
}

.footer .footer-item {
  text-align: left;
  width: 33.33%;
}

.footer .footer-item-title {

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #272d38;
}

.footer .footer-item-label {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-top: 43px;
  color: #555b66;
}

.footer .footer-qrcode {
  flex: 1;
}

.footer .footer-qrcode img {
  width: 188px;
  height: 188px;
}</style>