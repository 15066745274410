<template>
  <div>
    <header>
      <div class="header">
        <div class="header-top">
          <div class="center-box">
            <div class="header-top_left">
              <img @click="toAbout('/')" src="./img/logo.png" />
            </div>
            <div class="header-top_right">
              <div @click="toAbout('/about')"
                class="tab-item">
                关于我们
              </div>
              <div @click="toService()"
                class="tab-item">
                服务内容
              </div>
              <div 
                class="tab-item income" @click="showpop=!showpop" style="position:relative">
                加入我们
                <div :class="['pop-code',showpop?'show-pop':'' ]" >
                  <img src="./img/image.jpg" alt="">
                </div>
              </div>
              <div class="tab-item">
                <img class="search-icon" src="./img/Search.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="header-content">
          <div class="title">大湾区音乐产业创新服务中心</div>
          <div class="tips">
            Greater Bay Area Service Center For Music Industry Innovation
          </div>
          <div class="menu">
            <div class="menu-item" @click="toService()">音乐版权专业服务</div>
            <div class="menu-item" @click="toScrollId('cehua')">音乐内容运营策划</div>
          </div>
        </div>
      </div>
    </header>
    <section>
      <div class="copyright" ref="service">
        <div class="copyright-title">
          <div class="left-line"></div>
          <div class="title">音乐版权专业服务</div>
          <div class="right-line"></div>
        </div>
        <div class="copyright-tips">
          专业音乐版权交易平台，全方位一站式版权音乐服务
        </div>
        <div class="tab-box">
          <div>音乐确权</div>
          <div>音乐用权</div>
          <div>音乐维权</div>
        </div>
      </div>
      <div class="service">
        <div class="service-title">服务内容-全场景版权解决方案</div>
        <div class="service-content">
          <div class="serice-item">
            <div class="top-radius"></div>
            <div class="bottom-radius"></div>
            <div class="serice-item-title">音乐内容</div>
            <div class="serive-line"></div>
            <div class="serice-item-content">
              数百万曲库持续更新，包含经典流行、网络流行、纯音乐、音效等等
            </div>
          </div>
          <div class="serice-item">
            <div class="top-radius"></div>
            <div class="bottom-radius"></div>
            <div class="serice-item-title">权限范围</div>
            <div class="serive-line"></div>
            <div class="serice-item-content">
              词曲作品著作权、录音录像制作者权、表演者权，权益使用包含他项权等等
            </div>
          </div>
          <div class="serice-item">
            <div class="top-radius"></div>
            <div class="bottom-radius"></div>
            <div class="serice-item-title">覆盖场景</div>
            <div class="serive-line"></div>
            <div class="serice-item-content">
              多样化场景音乐用权解决方案：电商直播，才艺直播，商场演出，语聊社交，线下公播、广播电台、硬件设备、互联网 APP、教培机构 曲谱应用等
            </div>
          </div>
        </div>
      </div>

      <div class="mgoo">
        <div class="mgoo-title">音乐确权-MGOO音乐交易服务平台</div>
        <div class="mgoo-content">
          <div class="mgoo-left">
            <div class="mgoo-left-title">MGOO音乐交易服务平台</div>
            <div class="mgoo-left-desc">
              为音乐人提供更多版权变现价值，为音乐爱好者提供多一份音乐版权投资收入，鼓励音乐人才持续创作
            </div>
            <div class="mgoo-qrcode">
              <div class="mgoo-left-qrcode">
                <img src="./img/qrcode.png" alt />
              </div>
              <div class="mgoo-left-code">扫码体验音乐交易服务平台</div>
            </div>
          </div>
          <div class="mgoo-right">
            <img src="./img/phone1.png" alt />
            <img src="./img/phone2.png" alt />
          </div>
        </div>
        <div class="mgoo-title mt140">音乐用权-直播小程序音乐开放平台</div>
        <div class="mgoo-content mt131">
          <div class="mgoo-right">
            <img src="./img/phone3.png" alt />
            <img src="./img/phone4.png" alt />
          </div>
          <div class="mgoo-left2">
            <div class="mgoo-left-title">直播小程序音乐开放平台</div>
            <div class="mgoo-left-desc" >为直播场景提供一站式版权音乐解决方案平台功能：热度/标签排行、 歌曲推荐 、智能排序)、原声/伴奏随意切换使用场景：直播才艺展示、电台情感语聊等</div>
            <div class="mgoo-qrcode">
              <div class="mgoo-left-qrcode">
                <img src="./img/qrcode.png" alt />
              </div>
              <div class="mgoo-left-code">扫码体验音乐交易服务平台</div>
            </div>
          </div>
        </div>
        <div class="mgoo-radius-right"></div>
        <div class="mgoo-radius-left"></div>
      </div>

      <!-- <div class="cj-img">
        <img src="./img/img.png" alt />
      </div> -->
      <div ref="cehua"></div>
      <div class="copyright">
        <div class="copyright-title">
          <div class="left-line"></div>
          <div class="title">音乐内容运营策划</div>
          <div class="right-line"></div>
        </div>
        <div class="copyright-tips">
          涵盖艺人经纪、音乐和演出制作、版权和音乐服务。具备运营大型音乐节、音乐现场内容的丰富经验
        </div>
        <div class="tab-box">
          <div>艺人经纪</div>
          <div>音乐演出策划</div>
          <div>音乐宣发推广</div>
        </div>
      </div>
      <!-- <div class="x-desc">
        涵盖艺人经纪、音乐和演出制作、版权和音乐服务。具备运营大型音乐节、音乐现场内容的丰富经验
      </div> -->
      <div class="o-card">
        <div class="o-img">
          <img src="./img/u-img.jpg" alt />
        </div>
        <div class="o-item">
          <div class="o-item-title">苏打音乐节</div>
          <div class="o-item-desc">
            旨在开启校园⾳乐节的最新模式，全新定义年轻⼈的⽣活⽅式，制作⼀场植于校园的⼤型⾳乐节
          </div>
        </div>
      </div>
      <div class="o-card">
        <div class="o-item">
          <div class="o-item-title">春回音乐季</div>
          <div class="o-item-desc o-right20">
            最初发起于2021年春，比起演出，它更像“回春丹和朋友们”一年一度的随机聚会于春暖花开
          </div>
        </div>
        <div class="o-img">
          <img src="./img/o-img.jpg" alt />
        </div>
      </div>
      <div class="o-card">
        <div class="o-img">
          <img src="./img/m-.jpg" alt />
        </div>
        <div class="o-item">
          <div class="o-item-title">国潮音乐节</div>
          <div class="o-item-desc">
            通过挖掘高契度的内容及线上线下资源全渠道传播、全方位传递品牌理念，提升品牌关注度
          </div>
        </div>
      </div>
      <div class="active">
        <div class="active-title">往期合作项目</div>
        <div class="active-list" ref="scrollview2">
          <div class="active-list-item" v-for="(item, index) in banner" :key="index">
            <div class="active-img"><img :src="item.img" alt=""></div>
            <div class="active-detail">
              <div class="active-item-title">{{ item.title }}</div>
              <div class="active-item-desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="left-right">
            <div class="left-icon" >
              <!-- <img style="user-select: none;" v-if="scrollLeft != 0" src="./img/right.png" class=" rate90" alt=""> -->
              <img  src="./img/right-grey.png" style="user-select: none;" class="" alt="">
            </div>
            <div class="left-icon" >
              <!-- <img v-if="!is_right" src="./img/right.png" style="user-select: none;" class="" alt=""> -->
              <img  src="./img/right-grey.png" style="transform: rotate(180deg);user-select: none;" class="" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="active">
        <div class="active-title">年度现场</div>
        <div class="active-list " ref="scrollview">
          <div class="active-list-item active-list-item2" style="border:none" v-for="(item, index) in banner2" :key="index">
            <div class="active-img active-img2"><img :src="item.img" alt=""></div>
            <!-- <div class="active-detail">
              <div class="active-item-title">{{ item.title }}</div>
              <div class="active-item-desc">{{ item.desc }}</div>
            </div> -->
          </div>
        </div>
        <div class="center">
          <div class="left-right">
            <div class="left-icon" @click="bindScollLeft">
              <img style="user-select: none;" v-if="scrollLeft != 0" src="./img/right.png" class=" rate90" alt="">
              <img v-else src="./img/right-grey.png" style="user-select: none;" class="" alt="">
            </div>
            <div class="left-icon" @click="bindScollRight">
              <img v-if="!is_right" src="./img/right.png" style="user-select: none;" class="" alt="">
              <img v-else src="./img/right-grey.png" style="transform: rotate(180deg);user-select: none;" class="" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="partner">
        <div class="partner-title">战略合作伙伴</div>
        <div>
          <img class="partner-list" src="./img/Group 391.png" alt="" />
        </div>
        <img class="partner-list" src="./img/Group 392.png" alt="" />
      </div>
      <div class="power">
        <div class="power-title">权威资质</div>
        <div class="power-list">
          <img src="./img/1.jpg" />
          <img src="./img/2.jpg" />
          <img src="./img/3.jpg" />
          <img src="./img/4.jpg" />
        </div>
      </div>
      <div class="support">
        <div class="support-title">支持单位</div>
        <div class="support-list">
          <div>
            主导单位：广州市大湾区文化交流促进中心<br />
            运营单位：广东乐通凯宏文化发展有限责任公司
          </div>
        </div>
      </div>
    </section>

    <footer class="footer">
      <div class="footer-item">
        <div class="footer-item-title">友情链接</div>
        <div class="footer-item-label">广东南粤公证处</div>
        <div class="footer-item-label">广州市大湾区文化交流促进中心</div>
        <div class="footer-item-label">广东省流行音乐协会音乐版权委员会</div>
        <div class="footer-item-label">广东省演出行业协会</div>
      </div>
      <div class="footer-item" style="width: 400px;">
        <div class="footer-item-title">联系我们</div>
        <div class="footer-item-label">咨询热线：020 - 83513470</div>
        <!-- <div class="footer-item-label">服务时间：08:30 - 18:00</div> -->
        <div class="footer-item-label">客服邮箱：copyright-royalty@yinchuangfu.org.cn</div>
        <div class="footer-item-label" style="cursor: pointer;" @click="icp" >粤ICP备2022042374号-1</div>
      </div>
      
      <div class="footer-qrcode">
        <img src="./img/image 19.png" alt="" />
      </div>
    </footer>
  </div>
</template>

<script>
import banner1 from './img/banner1.jpg'
import banner2 from './img/banner2.jpg'
import banner3 from './img/banner3.jpg'
import banner4 from './img/banner4.jpg'
import banner5 from './img/banner5.jpg'
import banner6 from './img/6.jpg'
import banner7 from './img/7.jpg'
import banner8 from './img/8.jpg'
import banner9 from './img/9.jpg'
/**
 * 界面变速或匀速滚动，只关注前四个参数，后面的参数为减少变量定义而存在
 * @param {Number} initTop 当前浏览器的滚动的高度，可使用 document.documentElement.scrollTop 获取
 * @param {Number} offset 需要移动的距离
 * @param {Number} down 是否是向下滚动是则为 1，向上为 -1
 * @param {Bool} a 是否变速运动
 * @param {Number} moved 总移动距离
 * @param {Number} i 每次移动的距离，通过计算而来
 * @param {Number} m 实际移动的距离，带有方向，正数为向下，负数为向上，为减少变量定义而存在，无需关注
 */
 function anim(initTop, offset, down, a = false, moved = 0, i = 1, m = 0) {
  setTimeout(() => {
    i = a ? moved < offset - 128 ? i * 1.02 : i / 1.6 : 16;
    i = i < 1 ? 1 : i;
    i = i > 32 ? 32 : i;
    moved += i;
    m = i * down;
    if (moved > offset) m = moved - offset;
    document.documentElement.scrollTop = initTop + m;
    if (moved < offset) {
      anim(initTop + m, offset, down, a, moved, i);
    }
  }, 1);
}
export default {
  name: "App",
  data() {
    return {
      tabbar: ["关于我们", "服务内容", "加入我们"],
      banner: [
        // {
        //   title: '年度现场',
        //   desc: '夏洛特烦恼、莎士比亚别生气、恋爱吧人类、了不起的爹地',
        //   img: banner5,
        // },
        {
          title: '乐队的夏天',
          desc: '重塑雕像的权利、木马、海龟先生、五条人等具有代表性的中国青年乐队',
          img: banner1,
        },
        {
          title: '中国新说唱',
          desc: '王以太、刘聪、功夫胖等华语青年说唱音乐艺人',
          img: banner2,
        },
        // {
        //   title: '国潮音乐节',
        //   desc: '“国潮音乐嘉年华”通过挖掘高契度的内容全渠道传播',
        //   img: banner3,
        // },
        // {
        //   title: '开心麻花',
        //   desc: '夏洛特烦恼、莎士比亚别生气、恋爱吧人类、了不起的爹地',
        //   img: banner4,
        // },
      ],
      banner2:[
         {
          title: '',
          desc: '',
          img: banner6,
        },
        {
          title: '',
          desc: '',
          img: banner7,
        },
        {
          title: '',
          desc: '',
          img: banner8,
        },
        {
          title: '',
          desc: '',
          img: banner9,
        },
      ],
      scrollLeft: 0,
      scrollLeft1: 0,
      is_right: false,
      showpop:false
    };
  },
  mounted() {
    const scrollview = this.$refs.scrollview
    scrollview.addEventListener('scroll', this.scrollChange, true)
  },
  beforeDestroy() {
    const scrollview = this.$refs.scrollview
    // 移除监听
    scrollview.removeEventListener('scroll', this.scrollChange, true)
  },
  methods: {
    toAbout(path) {
      this.$router.push(path)
    },
    toService(){
      window.scrollTo({
        behavior: 'smooth',
        top:this.$refs.service.getBoundingClientRect().top-50
      })
    },
    toScrollId(id){
      window.scrollTo({
        behavior: 'smooth',
        top:this.$refs[id].getBoundingClientRect().top
      })
    },
    // 活动滚动
    scrollChange(e) {
      this.scrollLeft = e.target.scrollLeft
      this.is_right = this.scrollLeft >= e.target.scrollWidth - e.target.offsetWidth
    },
    bindScollRight() {
      this.$refs.scrollview.scrollTo({
        left:this.scrollLeft + 1000,
        behavior: 'smooth'
      });
    },
    bindScollLeft() { 
      this.$refs.scrollview.scrollTo({
        left:this.scrollLeft - 1000,
        behavior: 'smooth'
      });
    },
    icp(){
      window.open('https://beian.miit.gov.cn/')
    }
  }
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-sizing: border-box;
}

.header {
  width: 1920px;
  height: 1080px;
  box-sizing: border-box;
  background: url("./img/hedear-bg.jpg");
  background-repeat: no-repeat;
  background-size: 1920px 1080px;
}

.header .header-top {
  display: flex;
  width: 1920px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.header .header-top .center-box {
  width: 1440px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.header .header-top .center-box .header-top_left img {
  padding-top: 36px;
  width: 186px;
  height: 56px;
  cursor: pointer;
}

.header .header-top .center-box .header-top_right {
  display: inline-flex;
  padding-top: 30px;
  flex-wrap: nowrap;
  align-items: center;
}

.header .header-top .center-box .header-top_right .tab-item {
  /* margin: 0 20px; */
  margin-left: 56px;
  cursor: pointer;
  user-select: none;

}
.header-top_right .tab-item:hover{
  color: #FF57D0;;
}
.pop-code img{
  position: absolute;
  left: -50%;
  top: 100%;
  margin-top: 15px;
  transform: translateY(0px);
  height: 0;
  z-index: 98;
  width: 200px
}
.header .income:hover {
  color: #FF57D0;;
}

/* .header .income:hover .pop-code img{
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  height: 200px;
  width: 200px;
} */
.show-pop img{
  transform: translateY(0);
  transition: all 0.1s ease-in-out;
  height: 200px!important;
  width: 200px!important;;
}

.header .search-icon {
  width: 24px;
  height: 24px;
}

.header .header-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.header .header-content .title {
  font-style: normal;
  font-weight: 600;
  font-size: 78px;
  line-height: 78px;
  text-align: center;
  margin-top: 337px;
  text-transform: capitalize;
  color: #272d38;
}

.header .header-content .tips {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-top: 32px;
  text-align: center;
  text-transform: capitalize;
  color: #555b66;
}

.header .header-content .menu {
  display: flex;
}

.header .header-content .menu .menu-item {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: right;
  text-transform: capitalize;
  color: #ffffff;
  /* height: 56px; */
  /* width: 240px; */
  padding: 5px 30px;
  text-align: center;
  line-height: 56px;
  background: #ff57d0;
  margin: 64px 64px;
}

/* header --end */

/* copyright start */
.copyright {
  margin-top: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.copyright .copyright-title {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 120px; */
}

.copyright .copyright-title .title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #272d38;
  margin: 0 40px;
}

.copyright .copyright-title .left-line {
  width: 120px;
  height: 4px;
  background: linear-gradient(90deg, rgba(167, 254, 183, 0) 0%, #27beff 100%);
}

.copyright .copyright-title .right-line {
  width: 120px;
  height: 4px;
  background: linear-gradient(90deg, rgba(167, 254, 183, 0) 0%, #27beff 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.copyright-tips {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-top: 32px;
  text-align: center;
  text-transform: capitalize;
  color: #9ba1ad;
}

.copyright .tab-box {
  box-sizing: border-box;
  margin-top: 60px;
  width: 1440px;
  height: 96px;
  background: #ffffff;
  border: 1px solid #edf6ff;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px rgba(228, 200, 217, 0.25);
}

.copyright .tab-box div {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  width: 33.33%;
  text-transform: capitalize;
}

.copyright .tab-box div:nth-child(1) {
  color: #ff7b94;
  border-right: 2px solid #38abff;
}

.copyright .tab-box div:nth-child(2) {
  color: #867cff;
  border-right: 2px solid #38abff;
}

.copyright .tab-box div:nth-child(3) {
  color: #27beff;
}

/* copyright end */

/* service start */
.service {

}

.service .service-title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #272d38;
  margin-top: 200px;
}

.service .service-content {
  display: flex;
}

.service .service-content .serice-item {
  width: 440px;
  height: 302px;
  background: #ffffff;
  margin-top: 60px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #edf6ff;
  box-shadow: 0px 8px 20px rgba(168, 206, 241, 0.25);
}

.serice-item:nth-child(1) {
  margin-left: 240px;
}

.serice-item:nth-child(2) {
  margin-left: 60px;
}

.serice-item:nth-child(3) {
  margin-left: 60px;
}

.top-radius {
  width: 127px;
  height: 127px;
  position: absolute;
  border-radius: 50%;
  background: #fde9ff;
  top: -63px;
  left: -64px;
}

.bottom-radius {
  width: 127px;
  height: 127px;
  position: absolute;
  border-radius: 50%;
  background: #fde9ff;
  bottom: -63px;
  right: -64px;
}

.service .serice-item-title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  text-transform: capitalize;
  color: #f040ff;
}

.service .serice-item-content {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  margin: 0 30px;
  text-transform: capitalize;
  color: #9ba1ad;
}

.service .serive-line {
  width: 72px;
  height: 6px;
  margin-top: 32px;
  margin-bottom: 32px;
  background: linear-gradient(90deg, rgba(167, 254, 183, 0) 0%, #27beff 100%);
}

/* service end */

/* mgoo start */
.mgoo {
  width: 100%;
  height: 1731px;
  margin-top: 200px;
  position: relative;
  background: #fef1ff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mgoo .mgoo-radius-right {
  width: 377px;
  position: absolute;
  border-radius: 50%;
  height: 377px;
  right: 118px;
  z-index: 1;
  top: 600px;
  background: #ffcbf0;
}

.mgoo .mgoo-radius-left {
  width: 274px;
  height: 274px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 199px;
  top: 840px;
  background: #ffcbf0;
}

.mgoo .mgoo-title {
  text-align: center;
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  padding-top: 80px;
  color: #272d38;
}

.mgoo-content {
  display: flex;
  z-index: 2;
  position: relative;
  width: 1363px;
  margin-top: 84px;
  align-items: center;
}
.mt131{
  margin-top: 131px;
}
.mgoo .mt140 {
  margin-top: 51px;
}

.mgoo .mgoo-left {
  width: 461px;
  margin-right: 150px;
}

.mgoo .mgoo-left2 {
  width: 461px;
  margin-left: 155px;
}

.mgoo .mgoo-left-title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  text-transform: capitalize;
  position: relative;
  color: #272d38;
}

.mgoo .mgoo-left-title::before {
  content: " ";
  position: absolute;
  left: 10px;
  top: 10px;
  width: 10px;
  height: 10px;

  background: #FF57D0;
}

.mgoo .mgoo-left-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  text-transform: capitalize;
  color: #9ba1ad;
  margin-top: 30px;
  text-align:left
}

.mgoo-left-code {
  margin-top: 10px;
  width: 380px;
  height: 66px;
  background: #ffffff;
  border: 2px solid #ff57d0;
  font-weight: 500;
  font-size: 24px;
  box-sizing: border-box;
  text-align: left;
  color: #ff57d0;
  display: inline-flex; 
  align-items: center;
  justify-content: center;
}

.mgoo .mgoo-left-code div {
  line-height: 32px;
}

.colum-center {
  align-items: center;
}

.mgoo-qrcode {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mgoo-left-qrcode {
  /* width: 746px; */
}

.mgoo-left-qrcode img {
  width: 175.65px;
  height: 175.65px;
}

.mgoo .mgoo-right img {
  width: 270px;
  height: 551px;
}

.mgoo .mgoo-right img:nth-child(1) {
  padding-left: 34px;
}

.mgoo .mgoo-right img:nth-child(2) {
  padding-left: 134px;
}

/* mgoo end */

/* bg-img */
.cj-img {
  margin: 108px 0;
  box-sizing: border-box;
  width: 1920px;
}

.cj-img img {
  width: 1920px;
  box-sizing: border-box;
}

/* bg-img-end */

.x-desc {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #9ba1ad;
  margin-top: 200px;
  margin-bottom: 61px;
}

.o-card {
  width: 1440px;
  height: 243px;
  background: #ffffff;
  border: 1px solid #edf6ff;
  margin-left: 240px;
  margin-top: 60px;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  box-shadow: 0px 8px 20px rgba(228, 200, 217, 0.25);
}
.o-right20{
  margin-right: 20px;
}

.o-card .o-img img {
  height: 243px;
  width: 576px;
  /* width: 50%; */
  /* width: ; */
}

.o-item {
  text-align: left;
}

.o-card .o-item-title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  /* line-height: 32px; */
  margin-left: 101px;
  text-transform: capitalize;
  color: #5a91ff;
  position: relative;
}

.o-card .o-item-title::before {
  content: " ";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -20px;
  top: 12px;
  background: linear-gradient(90deg, rgba(167, 254, 183, 0) 0%, #87DBFF 100%);
}

.o-card .o-item-desc {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  margin-left: 91px;
  text-transform: capitalize;
  color: #9ba1ad;
  margin-top: 32px;
  word-break: break-all;
}

/* active start */
.active {
  text-align: center;
  margin-top: 200px;
}

.active .active-title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #272d38;
  margin-bottom: 57px;
}

.left-right {
  display: flex;
  margin-top: 68px;
}

.rate90 {
  transform: rotate(-180deg);
}

.left-icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin: 0 58px;
  border: 2px solid #CCD1DB;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.left-icon img {
  width: 10.46px;
  height: 20.83px;
}

.active-list {
  display: inline-flex;
  flex-wrap: nowrap;
  width: 1920px;
  overflow-x: scroll;
  transition: all 1s;
  /* height: 550px; */
}

.active-list::-webkit-scrollbar {
  display: none;
}

.active-list .active-list-item {
  width: 424px;
  /* height: 500px; */
  background: #FFFFFF;
  border: 1px solid #EDF6FF;
  box-shadow: 0px 8px 20px rgba(228, 200, 217, 0.25);
  margin-right: 84px;
  user-select: none;
}

.active-list .active-list-item:nth-child(1) {
  margin-left: 120px;
}

.active-detail {
  padding: 36px;
  text-align: left;
}
.active-list .active-list-item .active-img img {
  width: 424px;
  height: 289px;
}
.active-list .active-list-item .active-img2 img {
  width: 424px;
  /* box-shadow: 0px 8px 20px rgba(228, 200, 217, 0.25); */
  height: 292px;
  object-fit: fill;
}

.active-list .active-list-item .active-item-title {
  font-weight: 600;
  font-size: 32px;
  text-transform: capitalize;
  color: #272D38;
}

.active-list .active-list-item .active-item-desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-transform: capitalize;
  color: #9BA1AD;
  margin-top: 24px;

}

/* active end */

/* partner start */
.partner {
  margin-top: 250px;
}

.partner .partner-title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #272d38;
}

.partner .partner-list {
  width: 1344px;
  height: 63px;
  margin-top: 66px;
}

/* partner end */

/* power start */
.power {
  margin-top: 124px;
}

.power .power-title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #272d38;
}

.power .power-list img {
  width: 342.24px;
  margin: 0 11px;
  margin-top: 35px;
  height: 214.17px;
}

/* power end */

/* support start */
.support {
  margin-top: 108px;
}

.support .support-title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #272d38;
}

.support .support-list {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}

.support .support-list div {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-transform: capitalize;
  color: #555b66;
  width: 1440px;
  text-align: left;
}

/* support end */

/* footer */
.footer {
  display: flex;
  justify-content: center;
  margin-top: 260px;
  max-width: 1440px;
  margin-left: 240px;
  margin-bottom: 100px;
}

.footer .footer-item {
  text-align: left;
  width: 33.33%;
}

.footer .footer-item-title {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #272d38;
}

.footer .footer-item-label {
  /* font-family: "PingFang SC"; */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-top: 43px;
  color: #555b66;
}

.footer .footer-qrcode {
  flex: 1;
}

.footer .footer-qrcode img {
  width: 188px;
  height: 188px;
}</style>
