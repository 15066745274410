import Vue from 'vue'
import VueRouter from 'vue-router'
import index from './index.vue'
import about from './about.vue'
 
 
//使用路由插件
Vue.use(VueRouter)
 
const routes = [
    {
        path: '/',
        component:index
    },
    {
        path: '/about',
        component:about
    },
]
 
const router = new VueRouter({
    routes,
    mode:'hash'
})
 
export default router
 